body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
#root {
  width: 100%;
  background: linear-gradient(180deg, #92aef5 0%, #f4b7b7 100%);
  color: #ffffff;
  max-height: 100%;
  overflow: auto;
}

html {
  height: 100%;
}

* {
  box-sizing: inherit;
}
